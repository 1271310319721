<template>
  <div>
    <v-parallax
      dark
      :src="imgSrc"
      height="400"
    >
      <v-overlay absolute opacity="0.3" class="ma-0 pa-0">
        <v-row
          align="center"
          justify="center"
        >
          <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'h2' : 'h3']">
            DONATE
          </h1>
          <v-container class="py-0">
            <hr>
          </v-container>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-container>
            <h1 class="text-center" :class="[$vuetify.breakpoint.mdAndUp ? 'h35' : 'h5']">
              PLEASE JOIN US IN SUPPORTING OUR VISION OF REDUCING THE EFFECTS OF POVERTY IN THE LIVES OF BEAUTIFUL CHILDREN IN ANGOLA!!
            </h1>
          </v-container>
        </v-row>
      </v-overlay>
    </v-parallax>
    <DonateContent />
  </div>
</template>

<script>
import ProjectsBar from '@/components/hope/ProjectsBar.vue'
import DonateContent from '@/components/hope/DonateContent.vue'

export default {
  components: {
    DonateContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/slide4.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Donate'
      }
    }
  }
}
</script>
